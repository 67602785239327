import rank1ImageBig from 'assets/images/leaderboard/rank-1-big.svg';
import rank1Image from 'assets/images/leaderboard/rank-1.svg';
import rank2ImageBig from 'assets/images/leaderboard/rank-2-big.svg';
import rank2Image from 'assets/images/leaderboard/rank-2.svg';
import rank3ImageBig from 'assets/images/leaderboard/rank-3-big.svg';
import rank3Image from 'assets/images/leaderboard/rank-3.svg';
import rank4ImageBig from 'assets/images/leaderboard/rank-4-big.svg';
import rank4Image from 'assets/images/leaderboard/rank-4.svg';
import rank5ImageBig from 'assets/images/leaderboard/rank-5-big.svg';
import rank5Image from 'assets/images/leaderboard/rank-5.svg';
import { LeaderboardTableRowRankData } from 'models';

export const leaderboardTableRowRankData: LeaderboardTableRowRankData[] = [
  {
    rankImage: rank1Image,
    rankImageBig: rank1ImageBig,
    rankClass: 'rank-1',
    name: 'TOP 3',
    maxLevel: 3,
    description: '+30 PEX per day',
  },
  {
    rankImage: rank2Image,
    rankImageBig: rank2ImageBig,
    rankClass: 'rank-2',
    name: 'TOP 10',
    maxLevel: 10,
    description: '+20 PEX per day',
  },
  {
    rankImage: rank3Image,
    rankImageBig: rank3ImageBig,
    rankClass: 'rank-3',
    name: 'TOP 30',
    maxLevel: 30,
    description: '+10 PEX per day',
  },
  {
    rankImage: rank4Image,
    rankImageBig: rank4ImageBig,
    rankClass: 'rank-4',
    name: 'TOP 50',
    maxLevel: 50,
    description: '+4 PEX per day',
  },
  {
    rankImage: rank5Image,
    rankImageBig: rank5ImageBig,
    rankClass: 'rank-5',
    name: 'TOP 100',
    maxLevel: 100,
    description: '+2 PEX per day',
  },
];

export const getLeaderboardRankClass = (rank: number): string => {
  const applicableRankData = leaderboardTableRowRankData.find(
    (rankData) => rank <= rankData.maxLevel
  );

  return applicableRankData?.rankClass;
};
